// src/components/Sidebar.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import SaasproLogo from "../assets/images/saasprologo2.png"

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { logout } = useContext(AuthContext); // Use useContext to get logout from AuthContext

  return (
    <div
      className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-64 bg-[#0454F0] text-white p-4 z-50 flex flex-col justify-between lg:relative lg:translate-x-0`}
    >
      <div>
<img src={SaasproLogo} alt="logo" className='my-8' />
        <ul>
          <li className="mb-4">
            <Link to="/dashboard" className="block p-2 hover:bg-blue-600 rounded" onClick={toggleSidebar}>
              Dashboard
            </Link>
          </li>
          <li className="mb-4">
            <Link to="/dashboard/AllNotes" className="block p-2 hover:bg-blue-600 rounded" onClick={toggleSidebar}>
              All Notes
            </Link>
          </li>
        </ul>
      </div>
      <div className="mt-auto">
        <button
          className="w-full py-2 px-4 bg-red-600 hover:bg-red-700 rounded"
          onClick={() => {
            toggleSidebar(); // Close the sidebar
            logout(); // Call the logout function
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
